html,
body {
	width: 100%;
	height: 100%;
	margin: 0;
	padding: 0;
}

* {
	box-sizing: border-box;
	font-family: "poppins-regular";
	font-size: 1.1rem;
}

h1, h2{
	line-height: 1.5;
}

a {
	text-decoration: none;
}

@font-face {
	font-family: 'poppins-regular';
	src: url('../fonts/poppins-regular.ttf');
}

@font-face {
	font-family: 'poppins-bold';
	src: url('../fonts/poppins-bold.ttf');
}

h1,
h2,
h3,
h4 {
	font-family: 'poppins-bold';
}

@font-face {
	font-family: 'ico-testbox';
	src:  url('../fonts/ico-testbox.eot?5re0wh');
	src:  url('../fonts/ico-testbox.eot?5re0wh#iefix') format('embedded-opentype'),
	  url('../fonts/ico-testbox.ttf?5re0wh') format('truetype'),
	  url('../fonts/ico-testbox.woff?5re0wh') format('woff'),
	  url('../fonts/ico-testbox.svg?5re0wh#ico-testbox') format('svg');
	font-weight: normal;
	font-style: normal;
	font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
	/* use !important to prevent issues with browser extensions that change fonts */
	font-family: 'ico-testbox' !important;
	speak: never;
	font-style: normal;
	font-weight: normal;
	font-variant: normal;
	text-transform: none;
	line-height: 1;

	/* Better Font Rendering =========== */
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.icon-slack-circle:before {
	content: "\e90e";
}

.icon-slack:before {
	content: "\e90d";
}

.icon-linkedin:before {
	content: "\e90b";
}

.icon-youtube:before {
	content: "\e90c";
}

.icon-moon:before {
	content: "\e909";
}

.icon-sun:before {
	content: "\e90a";
}

.icon-menu:before {
	content: "\e908";
}

.icon-bug-tracker:before {
	content: "\e900";
}

.icon-documentation:before {
	content: "\e901";
}

.icon-github:before {
	content: "\e902";
}

.icon-help-group:before {
	content: "\e903";
}

.icon-supporters:before {
	content: "\e904";
}

.icon-home:before {
	content: "\e905";
}

.icon-facebook:before {
	content: "\e906";
}

.icon-twitter:before {
	content: "\e907";
}

.fs-7 {
	font-size: 0.9rem;
}

.fs-8 {
	font-size: 0.8rem;
}

.text-bold {
	font-family: "poppins-bold";
}

.poppins-regular-text {
	font-family: "poppins-regular";
}

.statistics-card {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;

	width: 242px;
	height: 105px;
}

.bg-header-config {
	padding-top: 10%;
	background-size: cover; 
	background-position: bottom center;
	background-repeat: no-repeat;
}

.h-training {
	height: 156px;
}

.bg-ortus {
	background-color: #8FC73E !important;
	border-color: #8FC73E !important;
}

.more-info-card {
	height: 269px;
}

.fs-more-info-icon {
	font-size: 90px !important;
}


// ### Customization of change theme control ###
.form-switch .form-check-input {
    --bs-form-switch-bg: none !important;
}

.form-check-input {
	height: 0.7em;
	margin-top: 0.4em;
	background-color: #2D7C99;
	border-radius: 50px;
	position: relative;
	transition: background-color 0.3s ease;
	
	&::before {
	  content: "\e909"; /* Moon Icon */
	  font-family: "ico-testbox";
	  font-weight: 900;
	  position: absolute;
	  top: 50%;
	  left: 20px;
	  transform: translateY(-50%) !important;
	  font-size: 16px;
	  color: #2D7C99;
	  transition: transform 0.3s ease, content 0.3s ease;
	  width: 22px;
	  height: 22px;
	  display: flex;
	  justify-content: center;
	  align-items: center;
	  border-radius: 99px;
	}
  
	&::after {
		content: "\e90a"; /* Sun Icon */
		font-family: "ico-testbox";
		font-weight: 900;
		position: absolute;
		top: 50%;
		right: 20px;
		transform: translateY(-50%);
		font-size: 16px;
		color: #2D7C99;
		background-color: #fff;
		width: 22px;
		transition: transform 0.3s ease, content 0.3s ease;
		height: 22px;
		border-radius: 99px;
		display: flex;
		justify-content: center;
		align-items: center;
		box-shadow: 4px 3px 5px #37363659;
	}
  
	&:checked {
		background-color: #2d7c9991; /* Custom background when checked */
		border-color: #2d7c9917 !important; 

	  &::before {
		color: #ffffff;
		border: 1px solid #2D7C99;
    	background-color: #2D7C99;
		content: "\e909" !important; /* Moon Icon - Checkmark icon */
		transform: translate(24px, -50%);
	  }
  
	  &::after {
		color: #208BB5 !important;
		content: none !important; /* Hide 'off' icon */
	  }
	}
}


.carousel-inner {
    overflow: hidden;
    position: relative;
    width: 100%;
    height: 600px;
}

.carousel-item {
    backface-visibility: hidden;
    display: none;
    float: left;
    margin-right: -100%;
    position: relative;
    transition: transform .6s ease-in-out;
    width: 100%;
    height: 600px;
}

.slider-img {
	height: 100%;
    width: auto;
    margin: 0 auto;
    display: block;
}

// Large devices (desktops, less than 1200px)
@media ( max-width: 1199.98px ) {
	.nav-tabs .nav-link.active, .nav-tabs .nav-item.show .nav-link {
		border-style: ridge;
		border-radius: .25rem;
	}
}